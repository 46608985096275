import { IAugmentedAuditLog } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import toast from 'features/toast';

const RELEVANT_FIELDS = ['status'];
export class BaseService<T extends { id: number | string }> {
  modulePrefix = 'base';
  canDelete = false;
  canBulkDelete = false;

  constructor(prefix) {
    this.modulePrefix = prefix;
  }

  public async getAllPaginated(limit = 0, offset = 0, params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/`, {
        params: { limit, offset, ...params }
      });
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async getAllBaseInformation(params: Record<string, unknown> = {}) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}-summary/`, { params });
      return res.data.map((entity) => ({
        ...entity,
        description: `${entity.code} - ${entity.description}`
      }));
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async get(id: number | string) {
    try {
      const res = await axiosPrivate.get(`${this.modulePrefix}/${id}/`);
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async delete(id: number) {
    try {
      const res = await axiosPrivate.delete(`${this.modulePrefix}/${id}/`);
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async add(entity: T) {
    try {
      const res = await axiosPrivate.post(`${this.modulePrefix}/`, entity);
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async update(id: number | string, entity: T | any) {
    try {
      const res = await axiosPrivate.patch(`${this.modulePrefix}/${id}/`, entity);
      return res.data;
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async bulkDelete() {
    try {
      if (this.canBulkDelete) {
        const res = await axiosPrivate.post(`${this.modulePrefix}/purge/`);
        return res.data;
      }
    } catch (e) {
      toast.error(e.message);
    }
  }

  public async options() {
    const res = await axiosPrivate.options(`${this.modulePrefix}/`);
    return res.data;
  }

  public async history(id: number | string): Promise<IAugmentedAuditLog[]> {
    const res = await axiosPrivate.get(`${this.modulePrefix}/${id}/history/`);
    return res.data.map((d: IAugmentedAuditLog) => {
      d.iconBackground = 'bg-primary-500';

      if (d && RELEVANT_FIELDS.some((field) => d.changes.includes(field))) {
        d.iconBackground = 'bg-red-500';
      }

      switch (d.action) {
        case 0:
          d.iconBackground = 'bg-green-500';
          d.icon = FiberNewIcon;
          break;
        case 1:
          d.icon = EditIcon;
          break;
        case 2:
        default:
          d.iconBackground = 'bg-red-500';
          d.icon = DeleteIcon;
          break;
      }
      return d;
    });
  }

  attachFiles = async (attachment: File, attachmentName: string, entityId: number) => {
    const res = await axiosPrivate.postForm(`${this.modulePrefix}/${entityId}/attach-files/`, {
      [attachmentName]: attachment
    });
    return res.data;
  };

  downloadAttachment = async (entityId: number, attachmentName: string) => {
    axiosPrivate
      .get(`${this.modulePrefix}/${entityId}/download-attachment/${attachmentName}/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  deleteAttachment = async (entityId: number, attachmentName: string) => {
    const res = await axiosPrivate.post(
      `${this.modulePrefix}/${entityId}/delete-attachment/${attachmentName}/`
    );
    return res.data;
  };

  getImportTemplate = async () => {
    axiosPrivate
      .get(`${this.modulePrefix}/import_template/`, {
        responseType: 'blob'
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const contentDisposition = res.headers['content-disposition'];
        if (contentDisposition?.indexOf('attachment') !== -1) {
          const filenameMatches = contentDisposition.match(
            /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/
          );
          const filename = filenameMatches.pop();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      });
  };
}
