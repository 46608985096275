import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { ticketingService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TicketColumns } from 'components/common/enhanced/common-headers';
import { Button, ButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export const Tickets = () => {
  const translationPrefix = 'pages.tickets';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const columns: GridColDef[] = TicketColumns();
  columns.splice(7, 1);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Link to="new">
                <Button variant="outlined" size="small">
                  <AddIcon />
                  {t(`${translationPrefix}.add`)}
                </Button>
              </Link>
              <Link to="archive">
                <Button variant="outlined" size="small" color="warning">
                  {t(`global.archive`)}
                </Button>
              </Link>
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid columns={columns} service={ticketingService} refresh={datagridRefresh} />
      </TenantPage>
    </>
  );
};

export default Tickets;
