import { ITransportDocumentLine } from 'interfaces';
import { BaseService } from './BaseService';

class TransportDocumentLineService extends BaseService<ITransportDocumentLine> {
  constructor() {
    super('transport-document-lines');
  }
}

export const transportDocumentLineService = new TransportDocumentLineService();
