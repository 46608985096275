import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import { TenantPage } from 'components/common';
import { loadingActions } from 'features';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { costElementService } from 'services/api';
import { periodicCostElementService } from 'services/api/PeriodicCostElementService';
import CostElementForm from '../CostElementForm';
import { useEffect, useState } from 'react';
import { ICostElement, IPeriodicCostElemet } from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Button, ButtonGroup } from '@mui/material';
import toast from 'features/toast';
import DeleteIcon from '@mui/icons-material/Delete';

export function EditCostElement() {
  const { t } = useTranslation();
  const translationPrefix = 'cost-analisys.cost-elements.edit';
  const navigate = useNavigate();

  const { costElementId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let isPeriodical = false;
  isPeriodical = searchParams.get('isPeriodical') === 'true';

  const [costElement, setCostElement] = useState<ICostElement | IPeriodicCostElemet>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    (isPeriodical ? periodicCostElementService : costElementService)
      .get(+costElementId)
      .then(setCostElement);
  }, []);

  const updateCostElement = async (values) => {
    dispatch(loadingActions.startLoading());

    (isPeriodical ? periodicCostElementService : costElementService)
      .update(costElement.id, values)
      .then(() => {
        toast.success('Elemento di costo aggiornato con successo');
      });
    dispatch(loadingActions.stopLoading());
  };

  const deleteCost = async () => {
    (isPeriodical ? periodicCostElementService : costElementService)
      .delete(costElement.id)
      .then(() => {
        toast.success('Elemento di costo eliminato');
        navigate('..');
      });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={costElement?.code}
      menuRight={
        <IsTenantAdministrator>
          <ButtonGroup size="small">
            <Button color="error" onClick={deleteCost}>
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </IsTenantAdministrator>
      }>
      <div>
        <CostElementForm
          onSubmit={updateCostElement}
          costElement={costElement}
          periodical={isPeriodical}
        />
      </div>
    </TenantPage>
  );
}

export default EditCostElement;
