import { NavLink, useNavigate } from 'react-router-dom';
import { purchaseRequestService } from 'services/api';
import { useTranslation } from 'react-i18next';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import toast from 'features/toast';
import { PurgeButton } from 'components/helpers/PurgeButton';
import { PurchaseLineColumns } from 'components/common/enhanced/common-headers/purchase-request';
import { GridColDef } from '@mui/x-data-grid-premium';

export const PurchaseRequestList = () => {
  const translationPrefix = 'pages.purchase-requests.list';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (requestToDelete) {
      setOpenConfirmationDialog(true);
    }
  }, [requestToDelete]);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    setRequestToDelete(null);
  };

  const handleDelete = () => {
    purchaseRequestService.delete(requestToDelete).then(() => {
      toast.success(t(`${translationPrefix}.actions.delete`));
      setOpenConfirmationDialog(false);
      setRequestToDelete(null);
      datagridRefresh.current();
    });
  };

  const columns: GridColDef[] = PurchaseLineColumns(setRequestToDelete);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div>
            <ButtonGroup>
              <NavLink to="new">
                <Button variant="outlined" color="primary" size="small">
                  {t(`${translationPrefix}.add-new`)}
                </Button>
              </NavLink>
              <PurgeButton service={purchaseRequestService} refresh={datagridRefresh} />
            </ButtonGroup>
          </div>
        }>
        <EnhancedDataGrid
          columns={columns}
          service={purchaseRequestService}
          refresh={datagridRefresh}
        />
        <div>
          <Dialog
            open={openConfirmationDialog}
            onClose={handleCloseConfirmationDialog}
            fullWidth
            maxWidth="md">
            <DialogTitle id="alert-dialog-title">
              {t(`${translationPrefix}.delete-confirmation`)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(`${translationPrefix}.delete-confirmation-extended`)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="w-full flex justify-between">
                <Button onClick={handleCloseConfirmationDialog}>{t('actions.cancel')}</Button>
                <div>
                  <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
                    {t('actions.delete')}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </TenantPage>
    </>
  );
};

export default PurchaseRequestList;
