import { ticketingService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ITicket } from 'interfaces';
import { useEffect, useState } from 'react';
import TicketForm from '../TicketForm';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';
import toast from 'features/toast';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import LockOutlined from '@mui/icons-material/LockOutlined';
import MailOutline from '@mui/icons-material/MailOutline';
import SectionTitle from 'components/common/SectionTitle';
import FileUploader from 'components/common/file-uploader';
import CloseTicketForm from './CloseTicketForm';

const downloadableFields = ['email_attachment', 'technical_report'];

export const Ticket = () => {
  const translationPrefix = 'pages.tickets';
  const { t } = useTranslation();

  const { ticketId } = useParams();
  const [ticket, setTicket] = useState<ITicket>();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    ticketingService.get(+ticketId).then(setTicket);
  }, []);

  const sendTicket = () => {
    ticketingService.sendTicket(+ticketId).then(() => {
      toast.success('Ticket inviato.');
    });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const closeTicket = (values) => {
    ticketingService.closeTicket(+ticketId, values).then((res) => {
      toast.success('Ticket chiuso.');
      ticketingService.get(+ticketId).then(setTicket);
    });
    setOpenDialog(false);
  };

  const updateTicket = (values) => {
    ticketingService.update(+ticketId, values).then((res) => {
      toast.success('Ticket aggiornato.');
      setTicket(res);
    });
  };

  return (
    <TenantPage
      title={t(`Ticket #${ticketId}`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={`#${ticketId}`}
      menuRight={
        <div className="flex justify-end items-center">
          <IsTenantAdministrator>
            <ButtonGroup>
              {ticket && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleOpenDialog}
                  disabled={
                    !(['OPEN', 'INTERVENTION_REQUESTED'].indexOf(ticket.status.display_name) > -1)
                  }>
                  <LockOutlined className="mr-2" />
                  {t(`${translationPrefix}.close`)}
                </Button>
              )}
            </ButtonGroup>
            <ButtonGroup>
              {ticket && (
                <Button variant="outlined" size="small" onClick={sendTicket} disabled={ticket.sent}>
                  <MailOutline className="mr-2" />
                  {ticket.sent_at !== null
                    ? t(`${translationPrefix}.sent`)
                    : t(`${translationPrefix}.send`)}
                </Button>
              )}
            </ButtonGroup>
          </IsTenantAdministrator>
        </div>
      }>
      <div className="flex flex-wrap">
        <div className="w-full p-4">
          <TicketForm ticket={ticket} onSubmit={updateTicket} />
        </div>
        <div className="p-4 w-full">
          <SectionTitle>{t(`global.attachments`)}</SectionTitle>
          <Card variant="outlined">
            <CardContent>
              <div className="flex flex-wrap">
                {ticket &&
                  downloadableFields.map((field, i) => (
                    <div key={i} className={`w-1/${downloadableFields.length}`}>
                      <span>{t(`entities.equipment.${field}`)}:</span>
                      <FileUploader
                        service={ticketingService}
                        entityId={ticket.id}
                        attachmentName={field}
                        filename={ticket[field]}
                      />
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="w-full md:w-1/2 p-4">
          {ticket && (
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={ticketingService} entityId={ticket.id} />
              </CardContent>
            </Card>
          )}
        </div>
      </div>
      <Dialog open={openDialog} maxWidth="lg" fullWidth>
        <DialogTitle>{t(`${translationPrefix}.close`)}</DialogTitle>
        <DialogContent>
          <CloseTicketForm onSubmit={closeTicket} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="error">
            {t('actions.cancel')}
          </Button>
          <Button type="submit" form="closeTicket">
            {t(`actions.confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </TenantPage>
  );
};

export default Ticket;
