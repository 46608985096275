import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import BiotechIcon from '@mui/icons-material/Biotech';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ViewListIcon from '@mui/icons-material/ViewList';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import BadgeIcon from '@mui/icons-material/Badge';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalculateIcon from '@mui/icons-material/Calculate';
import CodeIcon from '@mui/icons-material/Code';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import _ from 'lodash';

import Menu from './common/Menu';
import { ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR } from '../constants';

const navigation = [];
navigation.push({
  name: 'home',
  href: 'home',
  icon: DashboardIcon
});
navigation.push({ spacer: true });
navigation.push({
  name: 'contracts',
  href: 'contracts',
  icon: HandshakeIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR],
  contexts: ['LAB']
});
navigation.push({ name: 'suppliers', href: 'suppliers', icon: StorefrontIcon, contexts: ['LAB'] });
navigation.push({ name: 'products', href: 'products', icon: BiotechIcon, contexts: ['LAB'] });
navigation.push({
  name: 'warehouses',
  href: 'warehouses',
  icon: StoreIcon,
  contexts: ['LAB'],
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR]
});
navigation.push({
  name: 'skus',
  href: 'skus',
  icon: QrCode2Icon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['LAB']
});
navigation.push({
  name: 'custom-reports',
  href: 'custom-reports',
  icon: BackupTableIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['LAB']
});
navigation.push({ spacer: true });
navigation.push({
  name: 'equipments',
  href: 'equipments',
  end: true,
  icon: PrecisionManufacturingIcon,
  contexts: ['LAB']
});
navigation.push({
  name: 'maintenance',
  href: 'equipments/maintenance',
  icon: EngineeringIcon,
  contexts: ['LAB']
});
navigation.push({
  name: 'ticketing',
  href: 'equipments/ticketing',
  icon: ConfirmationNumberIcon,
  contexts: ['LAB']
});
navigation.push({
  name: 'equipment-invoices',
  href: 'equipments/invoices',
  icon: ReceiptIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['LAB']
});

navigation.push({
  name: 'categories',
  href: 'categories',
  icon: CategoryIcon,
  contexts: ['EVALUATE']
});
navigation.push({ spacer: true, contexts: ['LAB'] });
// Movements
navigation.push({
  name: 'movements',
  href: 'movements',
  icon: CompareArrowsIcon,
  contexts: ['LAB']
});
navigation.push({ name: 'stock', href: 'stock', icon: ShowChartIcon, contexts: ['LAB'] });
navigation.push({
  name: 'inventory-check',
  href: 'inventory-check',
  icon: ChecklistRtlIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR],
  contexts: ['LAB']
});
navigation.push({
  name: 'consumption',
  href: 'consumption',
  icon: LeaderboardIcon,
  contexts: ['LAB']
});
navigation.push({ spacer: true, contexts: ['LAB'] });
// Orders
navigation.push({
  name: 'purchase-requests',
  href: 'purchase_requests',
  icon: ViewListIcon,
  contexts: ['LAB']
});
navigation.push({
  name: 'orders',
  href: 'orders',
  icon: LocalShippingIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN, ROLE_TENANT_COORDINATOR],
  contexts: ['LAB']
});
navigation.push({
  name: 'ddt',
  href: 'ddt',
  icon: ListAltIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['LAB']
});
navigation.push({
  name: 'invoices',
  href: 'invoices',
  icon: LocalAtmIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['LAB']
});
navigation.push({ spacer: true, roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN], contexts: ['LAB'] });
// Reporting
navigation.push({
  name: 'reporting',
  href: 'reporting',
  icon: SummarizeIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['REFERTING']
});

// Custom dashboards and tabs defined in env
if (process.env.REACT_APP_METABASE_DASHBOARD_TABS) {
  process.env.REACT_APP_METABASE_DASHBOARD_TABS.split(',').map((context) => {
    navigation.push({
      name: context,
      href: _.kebabCase(context),
      icon: SummarizeIcon,
      roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
      translate: false,
      contexts: ['REFERTING']
    });
  });
}
navigation.push({ spacer: true, contexts: ['REFERTING'] });

navigation.push({
  name: 'cost-analysis',
  href: 'cost-analysis',
  icon: CalculateIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({
  name: 'cost-analysis-reports',
  href: 'cost-analysis/reports',
  icon: QueryStatsIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({
  name: 'costs',
  href: 'cost-analysis/costs',
  icon: PointOfSaleIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({
  name: 'exams',
  href: 'cost-analysis/exams',
  icon: MonitorHeartIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_COORDINATOR, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE', 'REFERTING']
});
navigation.push({
  name: 'regional-rates',
  href: 'cost-analysis/regional-rates',
  icon: RequestQuoteIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_COORDINATOR, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE', 'REFERTING']
});
navigation.push({
  name: 'medical-services',
  href: 'medical-services',
  icon: MedicalServicesIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE', 'REFERTING']
});
navigation.push({
  name: 'workstations',
  href: 'cost-analysis/workstations',
  icon: WarehouseIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({ spacer: true, contexts: ['REFERTING', 'EVALUATE'] });

// Referting
navigation.push({
  name: 'users',
  href: 'users',
  icon: PeopleAltIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({
  name: 'types',
  href: 'types',
  icon: CategoryIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({
  name: 'structure',
  href: 'topology',
  icon: BusinessIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({
  name: 'lis-transcodes',
  href: 'lis-transcodes',
  icon: CodeIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({
  name: 'department-transcodes',
  href: 'department-transcodes',
  icon: MultipleStopIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({
  name: 'settings',
  href: 'settings',
  icon: SettingsIcon,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['SETTINGS']
});
navigation.push({ spacer: true });
navigation.push({
  name: 'role-names',
  href: 'staff-management/role-names',
  icon: BadgeIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({
  name: 'expertises',
  href: 'staff-management/expertises',
  icon: AssignmentIndIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({
  name: 'activities',
  href: 'staff-management/activities',
  icon: WorkHistoryIcon,
  end: true,
  roles: [ROLE_SUPER_ADMIN, ROLE_TENANT_ADMIN],
  contexts: ['EVALUATE']
});
navigation.push({ spacer: true, contexts: ['EVALUATE'] });

interface TenantMenuProps {
  collapsed: boolean;
}

export const TenantMenu = (props: TenantMenuProps) => {
  const { collapsed } = props;

  return Menu(navigation, collapsed);
};

export default TenantMenu;
