import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValueFormatterParams,
  GridRenderCellParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import {
  dateFilterOperators,
  EquipmentInvoiceLineType,
  filterOperators,
  numberFilterOperators
} from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import DeleteIcon from '@mui/icons-material/Delete';
import { equipmentInvoiceLineService } from 'services/api';
import { currencyFormatter } from 'helpers/currency-formatter';

export function EquipmentInvoiceLinesColumns(datagridRefresh?: any): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        {
          if (datagridRefresh) {
            return [
              <GridActionsCellItem
                key={1}
                icon={<DeleteIcon />}
                onClick={() => {
                  equipmentInvoiceLineService.delete(+params.row.id);
                  datagridRefresh.current();
                }}
                label="Elimina"
              />
            ];
          } else {
            return [];
          }
        }
      },
      filterable: false
    },
    {
      field: 'contract_code',
      headerName: t(`global.contract`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 200,
      editable: true,
      filterable: false,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'type',
      headerName: t(`global.type`),
      width: 150,
      type: 'singleSelect',
      valueOptions: Object.entries(EquipmentInvoiceLineType).map(([key, value]) => {
        return { value, label: t(`pages.equipment-invoices.detail.modes.${value}`) };
      }),
      renderCell: (params: GridRenderCellParams<string>) =>
        t(`pages.equipment-invoices.detail.modes.${params.value}`)
    },
    {
      field: 'equipment',
      headerName: t(`global.equipment-or-service`),
      width: 200,
      filterOperators,
      renderCell: baseCell
      // valueFormatter: ({ value }) => {
      //   const values = value.map((value) => value.code);
      //   return values.join(', ');
      // },
      // renderCell: (params: GridRenderCellParams) => {
      //   const value = params.value.map((value) => value.code);
      //   return <DataGridCell content={value ? value.join(',') : ''} />;
      // }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'serial_number',
      headerName: t(`entities.equipment.serial_number`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'initialization_date',
      headerName: t(`global.date`),
      width: 200,
      filterOperators: dateFilterOperators,
      renderCell: baseCell,
      editable: true
    }
  ];

  ['amount', 'expected_amount'].forEach((colName) => {
    columns.push({
      field: colName,
      headerName: t(`entities.ddt.${colName}`),
      filterOperators: numberFilterOperators,
      editable: true,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    });
  });

  return columns;
}
