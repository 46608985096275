import { GridColumns } from '@mui/x-data-grid-premium';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import productStatusCell from '../cells/product-status-cell';

const statusOptions = ['ACTIVE', 'DECOMMISSIONED', 'TO_BE_DECOMMISSIONED', 'TENDER'];

export function ThresholdsColumns(): GridColumns {
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 400,
      renderCell: baseCell
    },
    {
      field: 'status',
      type: 'singleSelect',
      valueOptions: statusOptions,
      headerName: t(`entities.product.status`),
      editable: true,
      width: 100,
      renderCell: productStatusCell(t)
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      filterOperators,
      width: 350,
      editable: true,
      renderCell: baseCell
    }
  ];

  ['restock_threshold', 'understock_threshold', 'overstock_threshold'].forEach((threshold) => {
    columns.push({
      field: threshold,
      headerName: t(`entities.product.${threshold}`),
      type: 'number',
      width: 100,
      editable: true,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    });
  });

  return columns;
}
