import { Button, ButtonGroup } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { useTranslation } from 'react-i18next';
import { contractLineService, contractService } from 'services/api';

function EnhancedExportToolbar(entityId?: number, refresh?: any) {
  const { t } = useTranslation();

  const handleDelete = () => {
    contractService.purgeLines(entityId);
    if (refresh) refresh();
  };

  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <div className="w-auto flex justify-end">
          <ButtonGroup size="small">
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            <IsSuperAdmin>
              <ImportFileUploader service={contractLineService} entityId={entityId} />
              <Button color="error" onClick={handleDelete}>
                <DeleteIcon /> {t('global.purge')}
              </Button>
            </IsSuperAdmin>
          </ButtonGroup>
        </div>
      </GridToolbarContainer>
    );
  };
  return CustomToolBar;
}
export default EnhancedExportToolbar;
