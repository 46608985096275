import { productService, stockQuantityService, warehouseService } from 'services/api';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import WarehouseForm from '../WarehouseForm';
import { useEffect, useRef, useState } from 'react';
import { IOption, IStatusOption, IWarehouse } from 'interfaces';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { ThresholdsColumns } from 'components/common/enhanced/common-headers/thresholds';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import { GridColDef } from '@mui/x-data-grid-premium';
import { productWarehouseService } from 'services/api/ProductWarehouseService';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import toast from 'features/toast';
import { IsTechnicianOrAbove } from 'components/helpers/IsTechnicianOrAbove';

export function EditWarehouse() {
  const translationPrefix = 'pages.warehouse.edit';
  const { warehouseId } = useParams();
  const thresholdsRefresh = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [warehouse, setWarehouse] = useState<IWarehouse>();
  const [products, setProducts] = useState<IOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (warehouseId) {
      warehouseService.get(+warehouseId).then(setWarehouse);
      productService.getAllBaseInformation().then(setProducts);
    }
    productWarehouseService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices.map((item) => {
          return {
            ...item,
            display_name: t(`entities.product.status_options.${item.display_name}`)
          };
        })
      );
    });
  }, []);

  const updateWarehouse = async (values) => {
    dispatch(loadingActions.startLoading());
    warehouseService.update(warehouse.id, values).then((res) => {
      enqueueSnackbar('Magazzino modificato con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  const handleThresholdsBulkDelete = () => {
    productWarehouseService.bulkDeleteWarehouse(+warehouseId).then(() => {
      thresholdsRefresh.current();
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const addThreshold = (values) => {
    values['warehouse'] = +warehouseId;
    productWarehouseService.add(values).then((res) => {
      toast.success('Nuova soglia aggiunta con successo.');
      thresholdsRefresh.current();
      handleClose();
    });
  };

  const columns: GridColDef[] = StockQuantitiesColumns();
  const thresholdColumns = ThresholdsColumns();
  thresholdColumns.splice(3, 1);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={warehouse?.code}>
      {warehouse && (
        <div className="flex flex-wrap">
          <WarehouseForm warehouse={warehouse} onSubmit={updateWarehouse} />
          {/* Quantities */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <b className="text-primary-600">{t('menu.stock')}</b>
                <br />
                <EnhancedDataGrid
                  service={stockQuantityService}
                  columns={columns}
                  outerParams={{
                    warehouse__id: `${warehouse.id}`
                  }}
                />
              </CardContent>
            </Card>
          </div>
          {/* Thresholds */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <div className="flex justify-between">
                  <b className="text-primary-600">{t('menu.thresholds')}</b>
                  <br />
                  <ButtonGroup className="px-2">
                    <IsTechnicianOrAbove>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setOpenDialog(true)}
                        color="success">
                        <AddCircleOutlineIcon />
                      </Button>
                    </IsTechnicianOrAbove>
                    <IsSuperAdmin>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={handleThresholdsBulkDelete}
                        color="error">
                        <DeleteForeverIcon />
                      </Button>
                    </IsSuperAdmin>
                  </ButtonGroup>
                </div>
                <EnhancedDataGrid
                  service={productWarehouseService}
                  columns={thresholdColumns}
                  refresh={thresholdsRefresh}
                  outerParams={{
                    warehouse_id: `${warehouse.id}`
                  }}
                />
              </CardContent>
            </Card>
            <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleClose}>
              <DialogTitle>{t(`${translationPrefix}.add-threshold`)}</DialogTitle>
              <DialogContent>
                <ReactFinalForm onSubmit={addThreshold} formId="addProductThreshold" hideControls>
                  <div className="flex flex-wrap my-4">
                    <div className="w-1/6 mx-2">
                      {products && (
                        <IdentityField
                          name="product"
                          component={AutocompleteAdapter}
                          options={products}
                          label={t('global.product')}
                        />
                      )}
                    </div>
                    <div className="w-1/6 mx-2">
                      {statusOptions && (
                        <IdentityField
                          name="status"
                          label={t(`entities.product.status`)}
                          component={AutocompleteAdapter}
                          options={statusOptions}
                          placeholder="Status"
                          type="text"
                          optionLabel="display_name"
                          optionValue="value"
                        />
                      )}
                    </div>
                    <div className="w-1/6 mx-2">
                      <IdentityField
                        name="understock_threshold"
                        component={TextFieldAdapter}
                        label={t('entities.product.understock_threshold')}
                        type="number"
                      />
                    </div>
                    <div className="w-1/6 mx-2">
                      <IdentityField
                        name="overstock_threshold"
                        component={TextFieldAdapter}
                        label={t('entities.product.overstock_threshold')}
                        type="number"
                      />
                    </div>
                    <div className="w-1/6 mx-2">
                      <IdentityField
                        name="restock_threshold"
                        component={TextFieldAdapter}
                        label={t('entities.product.restock_threshold')}
                        type="number"
                      />
                    </div>
                    <div className="w-full mx-2 mt-4">
                      <IdentityField
                        name="notes"
                        component={TextFieldAdapter}
                        label={t('global.notes')}
                        type="text"
                      />
                    </div>
                  </div>
                </ReactFinalForm>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('actions.cancel')}</Button>
                <Button type="submit" form="addProductThreshold">
                  {t('global.add')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="w-full md:w-1/2  p-4">
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={warehouseService} entityId={warehouse.id} />
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </TenantPage>
  );
}

export default EditWarehouse;
