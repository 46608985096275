import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Tooltip } from '@mui/material';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';

export function ExamProductColumns(handleDelete): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 300,
      filterable: false,
      valueGetter: (params) => params.row.code,
      renderCell: (params: GridRenderCellParams<string>) => <DataGridCell content={params.value} />
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      flex: 1,
      filterable: false,
      valueGetter: (params) => params.row.description,
      renderCell: (params: GridRenderCellParams<string>) => <DataGridCell content={params.value} />
    },
    {
      field: 'quantity',
      headerName: t(`global.quantity`),
      width: 100,
      type: 'number',
      editable: true,
      renderCell: (params: GridRenderCellParams<string>) => <DataGridCell content={params.value} />
    },
    {
      field: 'actions',
      type: 'actions',
      width: 150,
      getActions: (params: GridRowParams) => [
        <IsTenantAdministrator key={1}>
          <GridActionsCellItem
            icon={
              <Tooltip title={t('actions.delete')} placement="top">
                <BackspaceIcon />
              </Tooltip>
            }
            onClick={() => handleDelete(params.row)}
            label="remove"
          />
        </IsTenantAdministrator>
      ]
    }
  ];
  return columns;
}
