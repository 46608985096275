import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import FileUploader from 'components/common/file-uploader';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import {
  filterOperators,
  dateFilterOperators,
  booleanFilterOperators,
  numberFilterOperators
} from './../../../../constants';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { equipmentService } from 'services/api';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { Chip } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import equipmentStatusCell from '../cells/equipment-status-cell';
import { currencyCell } from '../cells/currency-cell';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { booleanValueFormatter } from '../data-grid/utils';
import DataGridCell from 'components/common/DataGridCell';

const equipmentOptions = ['NEW', 'REFURBISHED', 'DECOMMISSIONED'];

export const EquipmentColumns = (
  ivdr_classes: string[],
  onUploadSuccess?: any,
  detailNav = false
): GridColDef[] => {
  const translationPrefix = 'entities.equipment';
  const { t } = useTranslation();

  const columns: GridColDef[] | GridColumns = [
    {
      field: 'code',
      headerName: t(`${translationPrefix}.code`),
      width: 200,
      filterOperators,
      renderCell: (params: GridRenderCellParams) => {
        const workstation = params.row;
        return detailNav ? (
          <div>
            <Link to={`${workstation.id}`}>
              <b className="text-secondary-500">{workstation.code}</b>
            </Link>
          </div>
        ) : (
          <DataGridCell content={params.value as string} />
        );
      }
    },
    {
      field: 'association',
      headerName: t(`global.association`),
      width: 80,
      sortable: false,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IsSuperAdmin>
            <Chip
              sx={{ background: 'transparent' }}
              icon={params.value ? <Check color="success" /> : <Close color="error" />}
            />
          </IsSuperAdmin>
        </div>
      )
    },
    {
      field: 'description',
      filterOperators,
      width: 250,
      headerName: t(`${translationPrefix}.description`)
    },
    {
      field: 'status',
      type: 'singleSelect',
      valueOptions: equipmentOptions,
      headerName: t(`${translationPrefix}.status`),
      width: 100,
      renderCell: equipmentStatusCell(t)
    },
    {
      field: 'serial_number',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.serial_number`)
    },
    {
      field: 'internal_id',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.internal_id`)
    },
    {
      field: 'transport_document_ref',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.transport_document_ref`)
    },
    {
      field: 'supplier',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.supplier`)
    },
    {
      field: 'position',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.position`)
    },
    {
      field: 'market_value',
      filterOperators: numberFilterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.market_value`),
      renderCell: currencyCell
    },
    {
      field: 'manufacturer',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.manufacturer`)
    },
    {
      field: 'yearly_lease_amount',
      headerName: t(`entities.contract-equipment.yearly_lease_amount`),
      width: 240,
      renderCell: currencyCell
    },
    {
      field: 'yearly_maintenance_amount',
      headerName: t(`entities.contract-equipment.yearly_maintenance_amount`),
      width: 240,
      renderCell: currencyCell
    },
    {
      field: 'purchase_amount',
      headerName: t(`entities.contract-equipment.purchase_amount`),
      width: 240,
      renderCell: currencyCell
    },
    {
      field: 'accessories',
      filterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.accessories`)
    },
    {
      field: 'delivery_date',
      filterOperators: dateFilterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.delivery_date`)
    },
    {
      field: 'installation_date',
      width: 150,
      filterOperators: dateFilterOperators,
      headerName: t(`${translationPrefix}.installation_date`)
    },
    {
      field: 'testing_date',
      filterOperators: dateFilterOperators,
      width: 150,
      headerName: t(`${translationPrefix}.testing_date`)
    }
  ];

  [
    'technical_file',
    'user_manual',
    'ce_ivd',
    'maintenance_checklist_template',
    'preemptive_maintenance_calendar'
  ].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`${translationPrefix}.${file}`),
      valueFormatter: booleanValueFormatter,
      renderCell: (params: GridRenderCellParams) => {
        const equipment = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                onUploadSuccess={onUploadSuccess}
                service={equipmentService}
                entityId={equipment.id}
                attachmentName={file}
                filename={equipment[file]}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    });
  });

  columns.push({
    field: 'actions',
    type: 'actions',
    headerName: t(`global.actions`),
    getActions: (params: GridRowParams) => {
      const actions = [];
      actions.push(
        <IsTenantAdministrator key={123}>
          <GridActionsCellItem
            icon={<LinkOffIcon />}
            onClick={() => {
              equipmentService.purgeLinks(+params.row.id).then(() => {
                if (onUploadSuccess) onUploadSuccess();
              });
            }}
            label="Unlink"
          />
        </IsTenantAdministrator>
      );
      return actions;
    }
  });

  return columns;
};
