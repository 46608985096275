import { examService } from 'services/api';
import { IExam } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ExamForm from '../ExamForm';
import { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';

export const EditExam = () => {
  const { t } = useTranslation();
  const { examId } = useParams();

  const [exam, setExam] = useState<IExam>();

  useEffect(() => {
    examService.get(+examId).then(setExam);
  }, []);

  const translationPrefix = 'pages.exams.edit';

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const update = async (values) => {
    dispatch(loadingActions.startLoading());
    examService.update(exam.id, values).then(() => {
      enqueueSnackbar('Esame aggiornato con successo', { variant: 'success' });
    });
    dispatch(loadingActions.stopLoading());
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={exam?.code}>
      <ExamForm exam={exam} onSubmit={update} />
      {exam && (
        <div className="flex flex-wrap mt-4">
          <div className="w-full md:w-1/2">
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={examService} entityId={exam.id} />
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </TenantPage>
  );
};

export default EditExam;
