import { useEffect, useState } from 'react';
import { IOption } from 'interfaces';
import { locationService } from 'services/api';
import { useTranslation } from 'react-i18next';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { IdentityField } from 'components/form/IdentityField';
import toast from 'features/toast';

interface AssignProductToLocationProps {
  productId: number;
  warehouses: IOption[];
  callback?: any;
}

const AssignProductToLocation = (props: AssignProductToLocationProps) => {
  const { productId, warehouses, callback } = props;
  const { t } = useTranslation();

  const [locations, setLocations] = useState<IOption[]>([]);

  const [warehouse, setWarehouse] = useState<IOption | null>(null);
  const [location, setLocation] = useState<IOption | null>(null);

  useEffect(() => {
    if (warehouse) {
      locationService.getAllBaseInformation({ warehouse: warehouse.id }).then((res) => {
        setLocations(res);
      });
    }
    setLocation(null);
    setLocations([]);
  }, [warehouse]);

  const onSubmit = (e) => {
    locationService.assignProduct(location.id, productId).then(() => {
      toast.success('Piazza aggiunta con successo.');
      if (callback) {
        callback();
      }
    });
  };

  return (
    <div className="w-full my-2 md:pr-2">
      <ReactFinalForm onSubmit={onSubmit}>
        <div className="w-full my-4">
          {warehouses && (
            <IdentityField
              name="warehouse"
              component={AutocompleteAdapter}
              initValue={warehouse}
              defaultValue={warehouse}
              setValue={setWarehouse}
              options={warehouses}
              label={t('global.warehouse')}
              type="text"
            />
          )}
        </div>
        <div className="w-full my-4">
          {locations && (
            <IdentityField
              name="locations"
              component={AutocompleteAdapter}
              initValue={locations}
              defaultValue={locations}
              options={locations}
              setValue={setLocation}
              label={t('global.location')}
              type="text"
            />
          )}
        </div>
      </ReactFinalForm>
    </div>
  );
};

export default AssignProductToLocation;
