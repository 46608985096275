import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import {
  categoryService,
  examTagService,
  productService,
  regionalRateService,
  workstationService
} from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IExam, IExamProduct, IOption, IRegionalRate } from 'interfaces';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/common/SectionTitle';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  Typography
} from '@mui/material';
import {
  DataGridPremium,
  GridCellEditCommitParams,
  GridColumns,
  itIT,
  MuiEvent
} from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';
import { ExamProductColumns } from 'components/common/enhanced/common-headers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { required } from 'helpers/validators';

interface ExamFormParams {
  exam: IExam;
  onSubmit: any;
}

function ExamForm(params: ExamFormParams) {
  const { t } = useTranslation();

  const { onSubmit, exam } = params;

  const [products, setProducts] = useState<IOption[]>([]);
  const [workstations, setWorkstations] = useState<IOption[]>([]);
  const [examTags, setExamTags] = useState<IOption[]>([]);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [regionalRates, setRegionalRates] = useState<IRegionalRate[]>([]);
  const [regionalRate, setRegionalRate] = useState<IRegionalRate | null>(null);
  const [examProducts, setExamProducts] = useState<IExamProduct[]>([]);

  useEffect(() => {
    productService.getAllBaseInformation().then(setProducts);
    workstationService.getAllBaseInformation().then(setWorkstations);
    examTagService.getAllPaginated().then(setExamTags);
    regionalRateService.getAllBaseInformation().then(setRegionalRates);
    categoryService.getAllBaseInformation().then(setCategories);
  }, []);

  useEffect(() => {
    if (exam?.regional_rate)
      setRegionalRate(regionalRates.find((s) => s.id === exam.regional_rate));
    else setRegionalRate(null);
    if (exam?.products) setExamProducts(exam.products);
  }, [exam]);

  const handleDelete = (line) => {
    setExamProducts((prevState) =>
      _.filter(prevState, function (p) {
        return p.id !== line.id;
      })
    );
  };

  const handleEdit = (params: GridCellEditCommitParams, event: MuiEvent) => {
    setExamProducts((prevState) =>
      prevState.map((p) => {
        if (p.id === params.id)
          return {
            ...p,
            [params.field]: params.value
          };
        return p;
      })
    );
  };

  const onAddProduct = (values) => {
    const productToAdd = {
      id: uuidv4(),
      product: values.product,
      code: products.find((p) => p.id === values.product).code,
      description: products.find((p) => p.id === values.product)['description'],
      quantity: values.quantity ?? 1
    };
    setExamProducts((prevState) => [...prevState, productToAdd]);
  };

  const regionalRateField = (field: string) => {
    if (regionalRates) {
      if (regionalRate) {
        const rate = regionalRates.find((s) => s.id === regionalRate.id);
        return rate ? rate[field] : '';
      }
      if (exam?.regional_rate) {
        const rate = regionalRates.find((s) => s.id === exam.regional_rate);
        return rate ? rate[field] : '';
      }
    }
    return '';
  };

  const columns: GridColumns = ExamProductColumns(handleDelete);

  return (
    <div>
      {exam && (
        <>
          <ReactFinalForm onSubmit={(values) => onSubmit({ ...values, products: examProducts })}>
            <div className="flex flex-wrap">
              <div className="w-full px-2 mb-2">
                <SectionTitle>{t('global.base-info')}</SectionTitle>
              </div>
              <div className="w-full mb-4">
                <IdentityField
                  initialValue={exam.code}
                  name="code"
                  component={TextFieldAdapter}
                  label={t('global.code')}
                  type="text"
                />
              </div>
              <div className="w-full mb-4">
                <IdentityField
                  initialValue={exam.description}
                  name="description"
                  component={TextFieldAdapter}
                  label={t('global.description')}
                  type="text"
                />
              </div>
              <div className="w-full mb-4">
                <IdentityField
                  name="category"
                  label={t('entities.exam.category')}
                  component={AutocompleteAdapter}
                  initValue={categories.find((s) => s.id === exam.category)}
                  defaultValue={exam.category}
                  options={categories}
                  type="text"
                />
              </div>
              <div className="w-full mb-4">
                {workstations && (
                  <IdentityField
                    name="workstations"
                    component={MultipleAutocompleteAdapter}
                    options={workstations}
                    initValue={exam.workstations}
                    label={t('global.workstations')}
                    type="text"
                  />
                )}
              </div>
              {/* <div className="w-full mb-4">
                {products && (
                  <IdentityField
                    defaultValue={exam.equipment}
                    name="equipment"
                    label={t('global.equipment')}
                    optionLabel="description"
                    component={MultipleAutocompleteAdapter}
                    options={products}
                    type="text"
                  />
                )}
              </div> */}
              <div className="w-1/2 pr-2">
                <IdentityField
                  initialValue={exam.contractual_rate}
                  name="contractual_rate"
                  component={TextFieldAdapter}
                  label={t('entities.exam.contractual_rate')}
                  type="number"
                />
              </div>
              <div className="w-1/2 pl-2">
                <IdentityField
                  name="tags"
                  component={MultipleAutocompleteAdapter}
                  freeSolo={true}
                  options={examTags}
                  optionValue={'code'}
                  initValue={exam?.tags}
                  label={t('entities.exam.tags')}
                  type="text"
                />
              </div>
              <div className="w-full mt-4">
                <IdentityField
                  initialValue={exam.notes}
                  name="notes"
                  component={TextFieldAdapter}
                  label={t('global.notes')}
                  type="text"
                />
              </div>
              <div className="w-full p-2 mt-2">
                <SectionTitle>{t('global.regional_price_list')}</SectionTitle>
              </div>
              {regionalRates && (
                <>
                  <div className="w-1/2 pr-2 mt-2">
                    <IdentityField
                      name="regional_rate"
                      label={t('entities.exam.regional_code')}
                      component={AutocompleteAdapter}
                      initValue={regionalRates.find((s) => s.id === exam.regional_rate)}
                      defaultValue={exam.regional_rate}
                      options={regionalRates}
                      setValue={setRegionalRate}
                      type="text"
                    />
                  </div>
                  <div className="w-1/2 mt-2 pl-2">
                    <TextField
                      value={regionalRateField('rate')}
                      label={t('entities.exam.regional_rate')}
                      type="number"
                      disabled
                      fullWidth
                    />
                  </div>
                  <div className="w-full mt-4">
                    <TextField
                      value={regionalRateField('description')}
                      label={t('entities.exam.price_list_description')}
                      type="text"
                      disabled
                      fullWidth
                    />
                  </div>
                </>
              )}
              <div className="w-full p-2 mt-2">
                <SectionTitle>{t('global.other_fares')}</SectionTitle>
              </div>
              <div className="w-full flex flex-row">
                {['rate_1', 'rate_2', 'rate_3', 'rate_4', 'rate_5'].map((field, idx) => (
                  <div className="py-2 mt-2 px-2 first:pl-0 last:pr-0 sm:w-1/3 md:w-1/5" key={idx}>
                    <IdentityField
                      name={field}
                      component={TextFieldAdapter}
                      defaultValue={exam && exam[field]}
                      label={t(`entities.exam.${field}`)}
                      type="number"
                    />
                  </div>
                ))}
              </div>
            </div>
          </ReactFinalForm>
          {/* Exam products */}
          <div className="w-full p-2 mt-2">
            <SectionTitle>{t('global.products')}</SectionTitle>
          </div>
          <Accordion className="bg-slate-50 mt-2">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('pages.purchase-requests.new.add-element')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-wrap">
                <div className="w-full p-2">
                  <ReactFinalForm onSubmit={onAddProduct} formId="addExamProduct" hideControls>
                    <div className="flex flex-wrap mb-2 w-full">
                      <div className="w-3/4 mb-4 pr-4">
                        <IdentityField
                          name="product"
                          component={AutocompleteAdapter}
                          options={products}
                          label={t('global.product')}
                          type="text"
                          validate={required}
                          required
                        />
                      </div>
                      <div className="w-1/4 mb-4">
                        <IdentityField
                          name="quantity"
                          component={TextFieldAdapter}
                          label={t('global.quantity')}
                          type="number"
                        />
                      </div>
                      <div className="w-full mb-4 py-auto flex flex-wrap justify-end">
                        <Button
                          type="submit"
                          form="addExamProduct"
                          variant="outlined"
                          className="w-full">
                          {t('global.add')}
                        </Button>
                      </div>
                    </div>
                  </ReactFinalForm>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="w-full mt-2">
            <DataGridPremium
              density="compact"
              rows={examProducts}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              sx={{ border: 'none' }}
              autoHeight
              disableSelectionOnClick
              columns={columns}
              onCellEditCommit={handleEdit}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ExamForm;
