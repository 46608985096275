import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { inventoryCheckService } from 'services/api';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import {
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { dateFilterOperators, filterOperators } from '../../../constants';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import { InventoryCheckColumns } from 'components/common/enhanced/common-headers';
import InventoryCheckDetail from './detail/InventoryCheckDetail';
import FactCheck from '@mui/icons-material/FactCheck';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import { booleanCell } from 'components/common/enhanced/cells/boolean-cell';
import { Check } from '@mui/icons-material';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import { Button, ButtonGroup, Chip, Tooltip, Typography, Zoom } from '@mui/material';
import React from 'react';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link, useNavigate } from 'react-router-dom';
import { loadingActions } from 'features';
import AddIcon from '@mui/icons-material/Add';

export const InventoryCheck = () => {
  const translationPrefix = 'pages.inventory-check';

  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const navigate = useNavigate();

  const aggregatedColumns: GridColumns = [
    {
      field: 'id',
      headerName: t(`global.id`),
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div>
            <Link to={`${params.value}`}>
              <b className="text-secondary-500">{params.value}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'created_by',
      headerName: t(`global.created_by`),
      width: 150,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'date',
      headerName: t(`global.date`),
      width: 150,
      filterOperators: dateFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 350,
      filterOperators,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 350,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'partial',
      headerName: t(`global.partial`),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'criterion',
      headerName: t(`global.criterion`),
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        return (params.value && t(`global.${params.value.toLowerCase()}`)) ?? '';
      }
    },
    {
      field: 'suppliers',
      headerName: t(`global.suppliers`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'locations',
      headerName: t(`global.locations`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'categories',
      headerName: t(`global.categories`),
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: baseCell
    },
    {
      field: 'is_valid',
      headerName: t(`global.status`),
      width: 100,
      type: 'boolean',
      renderCell: (params: GridRenderCellParams<string>) =>
        !params.row.open && (
          <Tooltip
            TransitionComponent={Zoom}
            arrow
            title={
              !params.row.is_valid &&
              params.row.errors.length > 0 && (
                <React.Fragment>
                  <Typography fontSize={12} color="inherit">
                    <ul>
                      {params.row.errors.map((error, i) => (
                        <li key={i}>- {error}</li>
                      ))}
                    </ul>
                  </Typography>
                </React.Fragment>
              )
            }>
            <Chip
              sx={{ background: 'transparent' }}
              icon={params.value ? <Check color="success" /> : <PriorityHigh color="warning" />}
            />
          </Tooltip>
        )
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        const actions = [
          <GridActionsCellItem
            key={1}
            label="Validate"
            icon={<FactCheck />}
            onClick={() => {
              loadingActions.startLoading();
              inventoryCheckService.validate(params.row.id).then(() => {
                datagridRefresh.current();
              });
              loadingActions.stopLoading();
            }}
          />,
          <GridActionsCellItem
            key={2}
            label="Archive"
            icon={<ArchiveIcon color="warning" />}
            onClick={() => {
              inventoryCheckService.archive(params.row.id).then(() => {
                datagridRefresh.current();
              });
            }}
          />,
          <GridActionsCellItem
            key={3}
            label="Delete"
            icon={<DeleteIcon color="error" />}
            onClick={() => {
              inventoryCheckService.delete(params.row.id).then(() => {
                datagridRefresh.current();
              });
            }}
          />
        ];
        return actions;
      },
      filterable: false
    }
  ];

  const columns: GridColDef[] = InventoryCheckColumns();

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <InventoryCheckDetail row={{ ...row }} columns={columns} />, [columns]);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <Button size="small" onClick={() => navigate('new')}>
                <AddIcon />
                {t(`${translationPrefix}.add`)}
              </Button>
            </ButtonGroup>
            <IsTenantAdministrator>
              <ButtonGroup>
                <Link to="archive">
                  <Button variant="outlined" size="small" color="warning">
                    {t(`global.archive`)}
                  </Button>
                </Link>
              </ButtonGroup>
            </IsTenantAdministrator>
          </div>
        }>
        <EnhancedDataGrid
          columns={aggregatedColumns}
          service={inventoryCheckService}
          refresh={datagridRefresh}
          getDetailPanelContent={getDetailPanelContent}
        />
      </TenantPage>
    </>
  );
};

export default InventoryCheck;
