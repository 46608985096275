import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import DataGridCell from 'components/common/DataGridCell';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import FileUploader from 'components/common/file-uploader';
import { productService } from 'services/api';
import {
  booleanFilterOperators,
  filterOperators,
  numberFilterOperators
} from './../../../../constants';
import { Link } from 'react-router-dom';
import { currencyFormatter } from 'helpers/currency-formatter';
import { baseCell } from '../cells/base-cell';
import { IsTenantCoordinatorOrAdmin } from 'components/helpers/IsTenantCoordinator';
import listCell from '../cells/list-cell';
import { booleanValueFormatter } from '../data-grid/utils';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { Check, Close } from '@mui/icons-material';
import { Chip } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';

export function ProductsColumns(
  getContractLines,
  types: string[],
  onUploadSuccess: any,
  sap_id = false,
  detailNav = false
): GridColDef[] | GridColumns {
  const { t } = useTranslation();
  const translationPrefix = 'entities.product';
  const columns: GridColDef[] | GridColumns = [
    {
      field: 'code',
      headerName: t(`global.code`),
      filterOperators,
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        const product = params.row;
        return detailNav ? (
          <div>
            <Link to={`${product.id}`}>
              <b className="text-secondary-500">{product.code}</b>
            </Link>
          </div>
        ) : (
          <DataGridCell content={params.value as string} />
        );
      }
    }
  ];

  if (sap_id) {
    columns.push({
      field: 'sap_id',
      headerName: t(`global.sap_id`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    });
  }

  columns.push(
    {
      field: 'association',
      headerName: t(`global.association`),
      width: 80,
      sortable: false,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <IsSuperAdmin>
            <Chip
              sx={{ background: 'transparent' }}
              icon={params.value ? <Check color="success" /> : <Close color="error" />}
            />
          </IsSuperAdmin>
        </div>
      )
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      width: 350,
      renderCell: baseCell
    },
    {
      field: 'ref',
      headerName: t(`${translationPrefix}.ref`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'type',
      headerName: t(`global.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'category',
      headerName: t(`global.category`),
      filterOperators,
      width: 100,
      renderCell: baseCell
    },
    {
      field: 'warehouses',
      headerName: t(`global.warehouses`),
      filterOperators,
      width: 200,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    },
    // {
    //   field: 'status',
    //   type: 'singleSelect',
    //   valueOptions: statusOptions,
    //   headerName: t(`${translationPrefix}.status`),
    //   width: 100,
    //   renderCell: productStatusCell(t)
    // },
    {
      field: 'contract_codes',
      valueGetter: getContractLines,
      headerName: t(`${translationPrefix}.contract_codes`),
      filterOperators,
      width: 200,
      valueFormatter: ({ value }) => {
        try {
          const contract_codes = value.map(({ contract_code }) => contract_code);
          return contract_codes.join(',');
        } catch {
          return '';
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const contract_codes = params.value.map(({ contract_code }) => contract_code);
        return <DataGridCell content={contract_codes.join(', ')} />;
      },
      sortable: false
    },
    {
      field: 'skus',
      headerName: t(`pages.sku.entity.sku`),
      filterOperators,
      width: 200,
      renderCell: listCell,
      filterable: false
    },
    {
      field: 'unit_prices',
      valueGetter: getContractLines,
      sortable: false,
      headerName: t(`${translationPrefix}.unit_prices`),
      valueFormatter: ({ value }) => {
        try {
          const unit_prices = value.map(({ unit_price }) => unit_price);
          return unit_prices.map(currencyFormatter, { significantDigits: 3 }).join(',');
        } catch {
          return '';
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const unit_prices = params.value.map(({ unit_price }) => unit_price);
        return (
          <IsTenantCoordinatorOrAdmin>
            <DataGridCell
              content={unit_prices.map(currencyFormatter, { significantDigits: 3 }).join(',')}
            />
            ;
          </IsTenantCoordinatorOrAdmin>
        );
      },
      width: 200,
      filterable: false
    },
    {
      field: 'suppliers',
      valueGetter: getContractLines,
      filterOperators,
      headerName: t(`${translationPrefix}.suppliers`),
      renderCell: (params: GridRenderCellParams) => {
        const suppliers = params.value.map(({ supplier }) => supplier);
        return <DataGridCell content={suppliers.join(',')} />;
      },
      valueFormatter: ({ value }) => {
        try {
          const suppliers = value.map(({ supplier }) => supplier);
          return suppliers.join(',');
        } catch {
          return '';
        }
      },
      width: 300,
      sortable: false
    },
    {
      field: 'expiration_alert_threshold',
      filterOperators,
      headerName: t(`${translationPrefix}.expiration_alert_threshold`),
      type: 'number',
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell
          content={
            params.value &&
            t(`${translationPrefix}.expiration_alert_threshold_field`, {
              n_days: params.value
            })
          }
        />
      )
    },
    {
      field: 'preservation_temperature',
      filterOperators,
      headerName: t(`${translationPrefix}.preservation_temperature`),
      type: 'number',
      renderCell: baseCell
    },
    {
      field: 'locations',
      filterOperators,
      headerName: t(`${translationPrefix}.locations`),
      renderCell: baseCell
    },
    {
      field: 'uom',
      headerName: t(`${translationPrefix}.uom`),
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'packaging',
      headerName: t(`${translationPrefix}.packaging`),
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'ivdr',
      headerName: t(`${translationPrefix}.ivdr_class`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'cnd_code',
      headerName: t(`${translationPrefix}.cnd_code`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'rdm_code',
      headerName: t(`${translationPrefix}.rdm_code`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    }
  );

  ['manufacturer_name', 'ce_regulation', 'id_issuing_authority'].forEach((field) => {
    columns.push({
      field: field,
      headerName: t(`${translationPrefix}.${field}`),
      filterOperators,
      renderCell: baseCell
    });
  });

  ['restock_threshold', 'understock_threshold', 'overstock_threshold'].forEach((threshold) => {
    columns.push({
      field: threshold,
      headerName: t(`${translationPrefix}.${threshold}`),
      type: 'number',
      width: 100,
      editable: true,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    });
  });

  ['technical_file', 'safety_document', 'ifu', 'ce_ivd'].forEach((file) => {
    columns.push({
      field: file,
      headerName: t(`${translationPrefix}.${file}`),
      valueFormatter: booleanValueFormatter,
      renderCell: (params: GridRenderCellParams) => {
        const product = params.row;
        return (
          <IsTenantAdministrator>
            <div>
              <FileUploader
                onUploadSuccess={onUploadSuccess}
                service={productService}
                entityId={product.id}
                attachmentName={file}
                filename={product[file]}
              />
            </div>
          </IsTenantAdministrator>
        );
      },
      width: 150,
      filterable: false,
      sortable: false
    });
  });

  columns.push({
    field: 'actions',
    type: 'actions',
    headerName: t(`global.actions`),
    getActions: (params: GridRowParams) => {
      const actions = [];
      actions.push(
        <IsTenantAdministrator key={123}>
          <GridActionsCellItem
            icon={<LinkOffIcon />}
            onClick={() => {
              productService.purgeLinks(+params.row.id).then(() => {
                if (onUploadSuccess) onUploadSuccess();
              });
            }}
            label="Unlink"
          />
        </IsTenantAdministrator>
      );
      return actions;
    }
  });

  return columns;
}
