import { useEffect, useState } from 'react';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { IOption, IStatusOption, ITicket } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import SectionTitle from 'components/common/SectionTitle';
import {
  contractEquipmentService,
  equipmentService,
  tenantSettingsService,
  ticketingService
} from 'services/api';
import { DateTimeTextField } from 'components/common';

interface TicketFormParams {
  ticket: ITicket;
  onSubmit: any;
}
function TicketForm(params: TicketFormParams) {
  const { t } = useTranslation();

  const [equipments, setEquipments] = useState<IOption[]>([]);
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);
  const [defaultMessage, setDefaultMessage] = useState<string | null>(null);

  useEffect(() => {
    contractEquipmentService.getAllBaseInformation().then((res) => {
      setEquipments(
        res.map((eq) => {
          return {
            id: eq['id'],
            code: `${eq['description']} (SN ${eq['serial_number']}) - (ID. ${eq['internal_id']})`
          };
        })
      );
    });
    tenantSettingsService.get(0).then((res) => setDefaultMessage(res.ticketing_default_email_body));
    ticketingService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices.map((item) => {
          return {
            ...item,
            display_name: t(`pages.tickets.status_options.${item.display_name}`)
          };
        })
      );
    });
  }, []);

  const { ticket, onSubmit } = params;
  return (
    <div>
      {ticket && defaultMessage !== null && (
        <ReactFinalForm
          onSubmit={onSubmit}
          edit={!!ticket.id}
          hideControls={
            !(['OPEN', 'INTERVENTION_REQUESTED'].indexOf(ticket.status.display_name) > -1)
          }>
          <div className="flex flex-wrap">
            <div className="w-1/3 p-4">
              <DateTimeTextField
                label={t(`entities.tickets.created_at`)}
                value={ticket.created_at}
                time
              />
            </div>
            {ticket.sent_at && (
              <div className="w-1/3 p-4">
                <DateTimeTextField
                  label={t(`entities.tickets.sent_at`)}
                  value={ticket.sent_at}
                  time
                />
              </div>
            )}
            {ticket.resolved_at && (
              <div className="w-1/3 p-4">
                <DateTimeTextField
                  label={t(`entities.tickets.resolved_at`)}
                  value={ticket.resolved_at}
                  time
                />
              </div>
            )}
            <div className="w-full p-2">
              <SectionTitle>{t('pages.supplier.new.registry')}</SectionTitle>
            </div>
            {ticket.id ? (
              <div className="w-full md:w-1/2 p-2">
                <IdentityField
                  name="contract_equipment"
                  component={TextFieldAdapter}
                  initialValue={`${ticket['equipment_description']} (SN ${ticket['serial_number']}) - (ID. ${ticket['internal_id']})`}
                  options={equipments}
                  placeholder={t('global.equipment')}
                  type="text"
                  disabled={true}
                />
              </div>
            ) : (
              <div className="w-full md:w-1/2 p-2">
                <IdentityField
                  name="contract_equipment"
                  component={AutocompleteAdapter}
                  options={equipments}
                  placeholder={t('global.equipment')}
                  type="text"
                />
              </div>
            )}
            {/* <div className="w-full md:w-1/2 p-2"> */}
            {/*   <IdentityField */}
            {/*     name="contract_equipment" */}
            {/*     component={AutocompleteAdapter} */}
            {/*     initValue={contracts.find((s) => s.code === ticket.contract)} */}
            {/*     options={contracts} */}
            {/*     placeholder={t('global.contract')} */}
            {/*     type="text" */}
            {/*     disabled={ */}
            {/*       !(['OPEN', 'INTERVENTION_REQUESTED'].indexOf(ticket.status.display_name) > -1) */}
            {/*     } */}
            {/*   /> */}
            {/* </div> */}
            {ticket.id && (
              <div className="p-2 w-full md:w-1/2">
                <IdentityField
                  name="status"
                  label={t(`global.status`)}
                  initValue={
                    ticket.status
                      ? {
                          ...ticket.status,
                          display_name: t(
                            `pages.tickets.status_options.${ticket.status.display_name}`
                          )
                        }
                      : {
                          value: 1,
                          display_name: t(`pages.tickets.status_options.OPEN`)
                        }
                  }
                  defaultValue={ticket.status ? ticket.status.value : 1}
                  component={AutocompleteAdapter}
                  options={statusOptions}
                  disabled
                  type="text"
                  optionLabel="display_name"
                  optionValue="value"
                />
              </div>
            )}
            <div className="w-full p-2">
              <IdentityField
                name="description"
                initialValue={ticket.description}
                component={TextFieldAdapter}
                label={t('global.description')}
                type="text"
                validate={required}
                required
                multiline
                rows={8}
              />
            </div>
            <div className="w-full p-2">
              <SectionTitle>{t('pages.tickets.section-email')}</SectionTitle>
            </div>
            <div className="w-full p-2">
              <IdentityField
                name="email_body"
                initialValue={ticket.id ? ticket.email_body : defaultMessage}
                component={TextFieldAdapter}
                label={t('global.message')}
                placeholder={t('global.message-placeholder')}
                type="text"
                validate={required}
                disabled={
                  !(['OPEN', 'INTERVENTION_REQUESTED'].indexOf(ticket.status.display_name) > -1)
                }
                required
                multiline
                rows={8}
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default TicketForm;
