import { movementReasonService, tenantSettingsService } from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import { IOption, ITenantSettings } from 'interfaces';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent } from '@mui/material';
import FileUploader from '../file-uploader';
import { MultipleAutocompleteAdapter } from 'components/form/MultipleAutocompleteAdapter';
import { useEffect, useState } from 'react';
import SectionTitle from '../SectionTitle';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface TenantSettingsFormParams {
  tenantSettings: ITenantSettings;
  onSubmit: any;
}

const TenantSettingsForm = (params: TenantSettingsFormParams) => {
  const { t } = useTranslation();
  const [movementReasons, setMovementReasons] = useState<IOption[]>([]);

  const { tenantSettings, onSubmit } = params;

  const downloadableFields = ['logo', 'logo_extra'];

  useEffect(() => {
    movementReasonService.getAllPaginated().then((res) => {
      setMovementReasons(res.results);
    });
  }, []);

  return (
    <div>
      {tenantSettings && (
        <div className="flex flex-wrap">
          <div className="py-4 w-full p-4">
            <div>
              <ReactFinalForm onSubmit={onSubmit}>
                <div className="flex flex-wrap">
                  <IsSuperAdmin>
                    <div className="p-2 w-full">
                      <IdentityField
                        name="error_reports_email"
                        label={t(`entities.tenant-settings.error_reports_email`)}
                        initialValue={tenantSettings.error_reports_email}
                        component={TextFieldAdapter}
                        type="email"
                      />
                    </div>
                    <div className="p-2 w-full">
                      <SectionTitle>{t('entities.tenant-settings.metabase-info')}</SectionTitle>
                    </div>
                    <div className="p-2 w-full">
                      <IdentityField
                        name="metabase_token"
                        label={t(`entities.tenant-settings.metabase_token`)}
                        initialValue={tenantSettings.metabase_token}
                        component={TextFieldAdapter}
                        type="text"
                      />
                    </div>
                  </IsSuperAdmin>
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.base-info')}</SectionTitle>
                  </div>
                  <div className="p-2 w-full md:w-1/4">
                    <IdentityField
                      name="language_code"
                      label={t(`entities.tenant-settings.language_code`)}
                      initialValue={tenantSettings.language_code}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.language_code')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/4">
                    <IdentityField
                      name="default_unload_reason"
                      component={AutocompleteAdapter}
                      options={movementReasons}
                      initValue={tenantSettings.default_unload_reason}
                      label={t('entities.tenant-settings.default_unload_reason')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/4">
                    <IdentityField
                      name="days_before_expiration_alert"
                      label={t(`entities.tenant-settings.days_before_expiration_alert`)}
                      initialValue={tenantSettings.days_before_expiration_alert}
                      component={TextFieldAdapter}
                      type="number"
                    />
                  </div>
                  <div className="p-2 w-full">
                    <SectionTitle>
                      {t('entities.tenant-settings.consumption-analysis')}
                    </SectionTitle>
                  </div>
                  <div className="p-2 w-full">
                    <IdentityField
                      name="cost_analysis_reasons"
                      component={MultipleAutocompleteAdapter}
                      options={movementReasons}
                      initValue={tenantSettings.cost_analysis_reasons}
                      label={t('entities.tenant-settings.cost-analysis-reasons')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.order-info')}</SectionTitle>
                  </div>
                  <div className="p-2 w-full md:w-1/3">
                    <IdentityField
                      name="company_name"
                      label={t(`entities.tenant-settings.company_name`)}
                      initialValue={tenantSettings.company_name}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.company_name')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="city"
                      label={t(`entities.tenant-settings.city`)}
                      initialValue={tenantSettings.city}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.city')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="district"
                      label={t(`entities.tenant-settings.district`)}
                      initialValue={tenantSettings.district}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.district')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="zip_code"
                      label={t(`entities.tenant-settings.zip_code`)}
                      initialValue={tenantSettings.zip_code}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.zip_code')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="country"
                      label={t(`entities.tenant-settings.country`)}
                      initialValue={tenantSettings.country}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.country')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/2">
                    <IdentityField
                      name="address"
                      label={t(`entities.tenant-settings.address`)}
                      initialValue={tenantSettings.address}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.address')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/2">
                    <IdentityField
                      name="address_2"
                      label={t(`entities.tenant-settings.address_2`)}
                      initialValue={tenantSettings.address_2}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.address_2')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.order-info-2')}</SectionTitle>
                  </div>
                  <div className="p-2 w-full md:w-1/3">
                    <IdentityField
                      name="company_name_extra"
                      label={t(`entities.tenant-settings.company_name`)}
                      initialValue={tenantSettings.company_name_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.company_name')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="city_extra"
                      label={t(`entities.tenant-settings.city`)}
                      initialValue={tenantSettings.city_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.city')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="district_extra"
                      label={t(`entities.tenant-settings.district`)}
                      initialValue={tenantSettings.district_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.district')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="zip_code_extra"
                      label={t(`entities.tenant-settings.zip_code`)}
                      initialValue={tenantSettings.zip_code_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.zip_code')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="country_extra"
                      label={t(`entities.tenant-settings.country`)}
                      initialValue={tenantSettings.country_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.country')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/2">
                    <IdentityField
                      name="address_extra"
                      label={t(`entities.tenant-settings.address`)}
                      initialValue={tenantSettings.address_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.address')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/2">
                    <IdentityField
                      name="address_2_extra"
                      label={t(`entities.tenant-settings.address_2`)}
                      initialValue={tenantSettings.address_2_extra}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.address_2')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.invoice-info')}</SectionTitle>
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="vat_number"
                      label={t(`entities.tenant-settings.vat_number`)}
                      initialValue={tenantSettings.vat_number}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.vat_number')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="cig"
                      label={t(`entities.tenant-settings.cig`)}
                      initialValue={tenantSettings.cig}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.cig')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="cuu"
                      label={t(`entities.tenant-settings.cuu`)}
                      initialValue={tenantSettings.cuu}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.cuu')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="email"
                      label={t(`entities.tenant-settings.email`)}
                      initialValue={tenantSettings.email}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.email')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="invoice_telephone"
                      label={t(`entities.tenant-settings.invoice_telephone`)}
                      initialValue={tenantSettings.invoice_telephone}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.invoice_telephone')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/6">
                    <IdentityField
                      name="invoice_email"
                      label={t(`entities.tenant-settings.invoice_email`)}
                      initialValue={tenantSettings.invoice_email}
                      component={TextFieldAdapter}
                      placeholder={t('entities.tenant-settings.invoice_email')}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full">
                    <SectionTitle>{t('entities.tenant-settings.communications')}</SectionTitle>
                  </div>
                  <div className="w-full p-2">
                    <IdentityField
                      name="orders_default_email_body"
                      initialValue={tenantSettings.orders_default_email_body}
                      component={TextFieldAdapter}
                      label={t('global.default_orders_message')}
                      placeholder={t('global.default_orders_message')}
                      type="text"
                      multiline
                      rows={8}
                    />
                  </div>
                  <div className="w-full p-2">
                    <IdentityField
                      name="ticketing_default_email_body"
                      initialValue={tenantSettings.ticketing_default_email_body}
                      component={TextFieldAdapter}
                      label={t('global.default_ticketing_message')}
                      placeholder={t('global.message-placeholder')}
                      type="text"
                      multiline
                      rows={8}
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/3">
                    <IdentityField
                      name="cc_orders"
                      label={t(`entities.tenant-settings.cc_orders`)}
                      placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                      initialValue={tenantSettings.cc_orders}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/3">
                    <IdentityField
                      name="cc_credit_memos"
                      label={t(`entities.tenant-settings.cc_credit_memos`)}
                      placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                      initialValue={tenantSettings.cc_credit_memos}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                  <div className="p-2 w-full md:w-1/3">
                    <IdentityField
                      name="cc_ticketing"
                      label={t(`entities.tenant-settings.cc_ticketing`)}
                      placeholder="mario.verdi@mail.it; francesco.bianchi@mail.it; franco.rossi@mail.it"
                      initialValue={tenantSettings.cc_ticketing}
                      component={TextFieldAdapter}
                      type="text"
                    />
                  </div>
                </div>
              </ReactFinalForm>
              <div className="py-4 w-full p-4">
                <Card variant="outlined">
                  <CardContent>
                    <SectionTitle>{t('global.attachments')}</SectionTitle>
                    <div className="flex flex-wrap">
                      {downloadableFields.map((field, i) => (
                        <div key={i} className={`w-1/${downloadableFields.length}`}>
                          <span>{t(`entities.tenant-settings.${field}`)}:</span>
                          <FileUploader
                            service={tenantSettingsService}
                            entityId={1}
                            attachmentName={field}
                            filename={tenantSettings[field]}
                          />
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TenantSettingsForm;
