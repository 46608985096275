import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { IEquipmentInvoice } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { equipmentInvoiceLineService, equipmentInvoiceService } from 'services/api';
import AddIcon from '@mui/icons-material/Add';
import { currencyFormatter } from 'helpers/currency-formatter';
import FileUploader from 'components/common/file-uploader';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import toast from 'features/toast';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import EquipmentInvoiceLineForm from './EquipmentInvoiceLineForm';
import { EquipmentInvoiceLinesColumns } from 'components/common/enhanced/common-headers/equipment-invoice-lines';
import DifferenceIcon from '@mui/icons-material/Difference';

export function EquipmentInvoiceDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.equipment-invoices.detail';
  const columns = EquipmentInvoiceLinesColumns();
  const { invoiceId } = useParams();
  const datagridRefresh = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [invoice, setInvoice] = useState<IEquipmentInvoice>();

  useEffect(() => {
    equipmentInvoiceService.get(+invoiceId).then(setInvoice);
  }, []);

  const downloadableFields = [
    'invoice_file',
    'ddt_file',
    'invoice_revision_file',
    'extra_1_file',
    'extra_2_file',
    'extra_3_file'
  ];

  const updateInvoice = (values) => {
    equipmentInvoiceService.update(+invoiceId, values).then((res) => {
      setInvoice(res);
      toast.success('Fattura aggiornata con successo');
    });
  };

  const downloadCreditMemo = () => {
    equipmentInvoiceService.downloadCreditMemo(+invoiceId);
  };

  const downloadInvoice = () => {
    equipmentInvoiceService.downloadInvoiceAgreement(+invoiceId);
  };
  const sendCreditMemo = () => {
    equipmentInvoiceService.emailCreditMemo(+invoiceId);
  };

  const sendInvoice = () => {
    equipmentInvoiceService.emailInvoiceAgreement(+invoiceId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = (values) => {
    equipmentInvoiceLineService.update(+values.invoice_line, { document: +invoiceId }).then(() => {
      handleCloseDialog();
      datagridRefresh.current();
    });
  };

  const generateCreditMemo = () => {
    equipmentInvoiceService.generateCreditMemo(+invoiceId).then(() => {
      equipmentInvoiceService.get(+invoiceId).then(setInvoice);
    });
  };

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + invoice?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      entityCode={invoice?.ref}
      menuRight={
        <div className="flex flex-wrap">
          <ButtonGroup size="small" className="mr-4">
            <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.credit-note`)}</Button>
            <Button onClick={generateCreditMemo}>
              <DifferenceIcon />
            </Button>
            {invoice && invoice.credit_memo_file && (
              <>
                <Button onClick={downloadCreditMemo}>
                  <DownloadIcon />
                </Button>
                <Button onClick={sendCreditMemo}>
                  <SendIcon />
                </Button>
              </>
            )}
          </ButtonGroup>
          <ButtonGroup size="small" className="mr-4">
            <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.invoice-agreement`)}</Button>
            <Button onClick={downloadInvoice}>
              <DownloadIcon />
            </Button>
            <Button onClick={sendInvoice}>
              <SendIcon />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small">
            <Button onClick={() => setOpenDialog(true)}>
              <AddIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <div>
        <div className="w-full">
          {invoice && invoice.amount != null && +invoice.amount !== +invoice.expected_amount && (
            <Alert severity="warning" className="w-full">
              {t(`${prefix}.incoerent-amounts`)}
            </Alert>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-3/4 pr-8">
              {invoice && (
                <ReactFinalForm onSubmit={updateInvoice} hideControls={true} formId="amountForm">
                  <div className="flex items-center">
                    <div className="mr-4">
                      <IdentityField
                        name="ref"
                        label={t(`entities.ddt.invoice_ref`)}
                        initialValue={invoice.ref}
                        component={TextFieldAdapter}
                        disabled={invoice.closed}
                      />
                    </div>
                    <div className="mr-4">
                      <IdentityField
                        name="date"
                        label={t(`entities.ddt.invoice_date`)}
                        component={DatePickerAdapter}
                        initialValue={dayjs(invoice.date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
                        disabled={invoice.closed}
                      />
                    </div>
                    <div className="mr-4">
                      <IdentityField
                        name="credit_memo_ref"
                        label={t(`entities.ddt.credit_memo_ref`)}
                        initialValue={invoice.credit_memo_ref}
                        component={TextFieldAdapter}
                        type="text"
                        disabled={+invoice.credit_memo_amount === 0}
                      />
                    </div>
                    <div className="mr-4">
                      <IdentityField
                        name="credit_memo_amount"
                        label={t(`entities.ddt.credit_memo_amount`)}
                        initialValue={currencyFormatter(invoice.credit_memo_amount)}
                        component={TextFieldAdapter}
                        type="text"
                        disabled
                      />
                    </div>
                    <div className="mr-4">
                      <IdentityField
                        name="credit_memo_date"
                        label={t(`entities.ddt.credit_memo_date`)}
                        component={TextFieldAdapter}
                        initialValue={invoice.credit_memo_date}
                        disabled
                      />
                    </div>
                    <div className="mr-4">
                      <IdentityField
                        name="closed"
                        label={invoice?.closed ? t(`entities.ddt.closed`) : t(`entities.ddt.open`)}
                        component={CheckboxAdapter}
                        type="checkbox"
                        initialValue={invoice?.closed ?? false}
                        icon={<LockOpenOutlinedIcon />}
                        checkedIcon={<LockIcon />}
                      />
                    </div>
                    <IconButton type="submit" form="amountForm" color="primary">
                      <SaveIcon />
                    </IconButton>
                  </div>
                </ReactFinalForm>
              )}
            </div>
            <div className="w-1/4">
              {invoice && (
                <div>
                  <b>{t(`entities.ddt.amount`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(invoice.amount)}</div>
                  <b>{t(`${prefix}.invoice-expected`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(invoice.expected_amount)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.files`)}</SectionTitle>
          {invoice && (
            <div className="flex flex-wrap">
              {downloadableFields.map((field, i) => (
                <div key={i} className={`w-1/${downloadableFields.length}`}>
                  <span>{t(`entities.ddt.${field}`)}:</span>
                  <FileUploader
                    service={equipmentInvoiceService}
                    entityId={+invoiceId}
                    attachmentName={field}
                    filename={invoice[field]}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        {invoice && (
          <div className="mb-4 mt-6">
            <SectionTitle>{t(`${prefix}.add-invoice-line`)}</SectionTitle>
            <Dialog open={openDialog} maxWidth="lg" fullWidth>
              <DialogTitle>{t(`${prefix}.add-invoice-line`)}</DialogTitle>
              <DialogContent>
                <EquipmentInvoiceLineForm onSubmit={onSubmit} contractId={+invoice.contract} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="error">
                  {t('actions.cancel')}
                </Button>
                <Button type="submit" form="newInvoice">
                  {t(`actions.confirm`)}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {invoice && (
          <EnhancedDataGrid
            columns={columns}
            service={equipmentInvoiceLineService}
            outerParams={{ document: invoiceId }}
            refresh={datagridRefresh}
          />
        )}
      </div>
    </TenantPage>
  );
}

export default EquipmentInvoiceDetail;
