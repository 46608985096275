import { ticketingService } from 'services/api';
import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import TicketForm from '../TicketForm';
import { ITicket } from 'interfaces';
import toast from 'features/toast';
import { useNavigate } from 'react-router-dom';

const initTicket: ITicket = {
  id: null,
  equipment: null,
  description: null,
  status: {
    value: 1,
    display_name: 'OPEN'
  }
};

export const AddTicket = () => {
  const translationPrefix = 'pages.tickets';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    ticketingService.add(values).then((res) => {
      toast.success('Ticket creato con successo.');
      navigate('../');
    });
  };

  return (
    <TenantPage title={t(`${translationPrefix}.add`)} subtitle="">
      <TicketForm ticket={initTicket} onSubmit={onSubmit} />
    </TenantPage>
  );
};

export default AddTicket;
