import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { currencyFormatter } from 'helpers/currency-formatter';
import { useTranslation } from 'react-i18next';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import { currencyCell } from '../cells/currency-cell';

export function TransportDocumentLinesColumns(locked = false, headerInfo = true): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'quantity',
      headerName: t(`global.quantity`),
      width: 150,
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'amount',
      headerName: t(`entities.ddt.amount`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      editable: !locked,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    {
      field: 'expected_amount',
      headerName: t(`entities.ddt.expected_amount`),
      filterOperators: numberFilterOperators,
      type: 'number',
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value);
      },
      renderCell: currencyCell
    },
    {
      field: 'ref',
      headerName: t(`entities.ddt.ref`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    }
  ];

  if (headerInfo) {
    columns.push(
      ...[
        {
          field: 'invoice_ref',
          headerName: t(`entities.ddt.invoice_ref`),
          filterOperators,
          width: 150,
          renderCell: baseCell
        },
        {
          field: 'credit_memo_ref',
          headerName: t(`entities.ddt.credit_memo_ref`),
          filterable: false,
          sortable: false,
          width: 200,
          renderCell: baseCell
        },
        {
          field: 'credit_memo_date',
          headerName: t(`entities.ddt.credit_memo_date`),
          filterable: false,
          sortable: false,
          width: 200,
          renderCell: baseCell
        },
        {
          field: 'credit_memo_amount',
          headerName: t(`entities.ddt.credit_memo_amount`),
          filterable: false,
          sortable: false,
          width: 200,
          valueFormatter: (params: GridValueFormatterParams) => {
            return currencyFormatter(params.value);
          },
          renderCell: currencyCell
        }
      ]
    );
  }

  columns.push(
    ...[
      {
        field: 'product',
        headerName: t(`global.product`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'description',
        headerName: t(`global.description`),
        width: 350,
        renderCell: baseCell
      },
      {
        field: 'contract',
        headerName: t(`global.contract`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'supplier',
        headerName: t(`global.supplier`),
        width: 200,
        renderCell: baseCell
      },
      {
        field: 'warehouse',
        headerName: t(`global.warehouse`),
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'date',
        headerName: t(`entities.ddt.movement_date`),
        filterOperators,
        width: 150,
        renderCell: baseCell
      },
      {
        field: 'discounted',
        headerName: t(`entities.movement.discounted`),
        renderCell: booleanCell(t)
      },
      {
        field: 'unit_price',
        headerName: t(`entities.movement.unit_price`),
        filterOperators: numberFilterOperators,
        type: 'number',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
          return currencyFormatter(params.value);
        },
        renderCell: currencyCell
      },
      {
        field: 'order_code',
        headerName: t(`entities.movement.order_code`),
        filterOperators,
        width: 150,
        renderCell: baseCell
      }
    ]
  );
  return columns;
}
