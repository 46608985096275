import { ITicket } from 'interfaces';
import { axiosPrivate } from 'services/axios';
import { BaseService } from './BaseService';

class TicketingService extends BaseService<ITicket> {
  constructor() {
    super('tickets');
  }
  canDelete = true;

  getClosed = async (limit = 0, offset = 0, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.get(`${this.modulePrefix}/get_closed/`, {
      params: { limit, offset, ...params }
    });
    return res.data;
  };

  sendTicket = async (ticketId: number) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${ticketId}/send-ticket/`, {});
    return res.data;
  };

  closeTicket = async (ticketId: number, params: Record<string, unknown> = {}) => {
    const res = await axiosPrivate.post(`${this.modulePrefix}/${ticketId}/close-ticket/`, params);
    return res.data;
  };
}

export const ticketingService = new TicketingService();
