import { ButtonGroup, Card, CardContent, IconButton } from '@mui/material';
import TenantPage from 'components/common/TenantPage';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  costElementService,
  laboratoryService,
  locationService,
  sectorService,
  warehouseService
} from 'services/api';
import { BaseService } from 'services/api/BaseService';
import DeleteIcon from '@mui/icons-material/Delete';
import { ILaboratory, ILocation, IOperationalUnit, ISector, IWarehouse } from 'interfaces';
import LaboratoryForm from '../LaboratoryForm';
import WarehouseForm from '../../../inventory/Warehouses/WarehouseForm';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import LocationForm from '../LocationForm';
import SectorForm from '../SectorForm';
import { periodicCostElementService } from 'services/api/PeriodicCostElementService';
import SectionTitle from 'components/common/SectionTitle';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { CostElementsColumns } from 'components/common/enhanced/common-headers/cost-elements';
import { productWarehouseService } from 'services/api/ProductWarehouseService';
import { ThresholdsColumns } from 'components/common/enhanced/common-headers/thresholds';
import { operationalUnitService } from 'services/api/OperationalUnitService';
import OperationalUnitForm from '../OperationalUnitForm';

function EditLayer() {
  const { layer, id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const costsPrefix = 'pages.cost.list';

  const columns = CostElementsColumns();
  const thresholdColumns = ThresholdsColumns();
  const periodicCostColumns = CostElementsColumns(true);

  const [layerType, setLayerType] = useState<any>();
  const [service, setService] = useState<BaseService<any>>();
  const [entity, setEntity] = useState<
    ILaboratory | IOperationalUnit | ISector | IWarehouse | ILocation
  >();

  useEffect(() => {
    costElementService.getLayerOptions().then((layerTypes) => {
      const _layerType = layerTypes.find((lt) => lt.model === layer);
      setLayerType(_layerType.id);
    });

    switch (layer) {
      case 'laboratory':
        setService(laboratoryService);
        break;
      case 'operationalunit':
        setService(operationalUnitService);
        break;
      case 'sector':
        setService(sectorService);
        break;
      case 'warehouse':
        setService(warehouseService);
        break;
      case 'location':
        setService(locationService);
        break;
    }
  }, []);

  const getOneShotCostElements = async (
    limit = 0,
    offset = 0,
    params: Record<string, unknown> = {}
  ) => {
    const res = await costElementService.getAllPaginated(limit, offset, {
      object_id: id,
      content_type: layerType,
      ...params
    });
    return res;
  };

  const getPeriodicCostElements = async (
    limit = 0,
    offset = 0,
    params: Record<string, unknown> = {}
  ) => {
    const res = await periodicCostElementService.getAllPaginated(limit, offset, {
      object_id: id,
      content_type: layerType,
      ...params
    });
    return res;
  };

  useEffect(() => {
    if (service) service.get(+id).then(setEntity);
  }, [service]);

  const deleteEntity = () => {
    if (confirm('are you sure?')) {
      service.delete(+id).then(() => navigate('..'));
    }
  };

  const updateEntity = (values) => {
    service
      .update(entity.id, values)
      .then(() => enqueueSnackbar(t('messages.entity-updated'), { variant: 'success' }));
  };

  return (
    <TenantPage
      title={''}
      subtitle={''}
      entityCode={layer['code']}
      menuRight={
        <ButtonGroup>
          <IconButton onClick={() => deleteEntity()}>
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      }>
      <div>
        {layer === 'laboratory' && <LaboratoryForm laboratory={entity} onSubmit={updateEntity} />}
        {layer === 'operationalunit' && (
          <OperationalUnitForm
            operational_unit={entity as IOperationalUnit}
            onSubmit={updateEntity}
          />
        )}
        {layer === 'sector' && <SectorForm sector={entity as ISector} onSubmit={updateEntity} />}
        {layer === 'warehouse' && (
          <div>
            <WarehouseForm warehouse={entity as IWarehouse} onSubmit={updateEntity} />
            {/* Thresholds */}
            <div className="w-full p-4">
              <Card variant="outlined">
                <CardContent>
                  <b className="text-primary-600">{t('menu.thresholds')}</b>
                  <br />
                  <EnhancedDataGrid
                    service={productWarehouseService}
                    columns={thresholdColumns}
                    outerParams={{
                      warehouse_id: id
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        )}
        {layer === 'location' && (
          <LocationForm location={entity as ILocation} onSubmit={updateEntity} />
        )}
      </div>
      {!!layerType && (
        <>
          <SectionTitle>{t(`${costsPrefix}.applied_costs`)}</SectionTitle>
          <div className="my-1">
            <SectionTitle>{t(`${costsPrefix}.extraordinary_costs`)}</SectionTitle>
          </div>
          <div>
            <EnhancedDataGrid
              columns={columns}
              service={costElementService}
              getMethod={getOneShotCostElements}
            />
          </div>
          <div className="my-1">
            <SectionTitle>{t(`${costsPrefix}.periodic_costs`)}</SectionTitle>
          </div>
          <div>
            <EnhancedDataGrid
              columns={periodicCostColumns}
              service={periodicCostElementService}
              getMethod={getPeriodicCostElements}
            />
          </div>
        </>
      )}
    </TenantPage>
  );
}

export default EditLayer;
