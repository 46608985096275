import { Button, ButtonGroup } from '@mui/material';
import { contractService } from 'services/api';
import { IContract, IContractLine, IOption } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ContractLineForm from '../ContractLineForm';
import ContractHeaderForm from '../ContractHeaderForm';
import ContractInsights from '../ContractInsights';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { ContractLinesColumns } from 'components/common/enhanced/common-headers/contract-lines';
import { useNavigate } from 'react-router-dom';
import SectionTitle from 'components/common/SectionTitle';

const initContract: IContract = {
  code: null,
  active: true,
  laboratories: [],
  supplier: { id: null, code: null },
  drafting_date: null,
  expiration_date: null,
  id: 0,
  product: 0,
  unit_price: 0,
  units_per_year: 0,
  commercial_bid: false,
  bid_approval: false
};
export const AddContract = () => {
  const translationPrefix = 'pages.contract.new';
  const navigate = useNavigate();

  const [products, setProducts] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [contractLines, setContractLines] = useState<IContractLine[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    dispatch(loadingActions.startLoading());
    contractService
      .add({
        ...values,
        lines: contractLines.map((cl) => {
          return { ...cl, product: cl.product['id'] };
        })
      })
      .then((res) => {
        if (res.id) {
          enqueueSnackbar('Contratto aggiunto con successo', { variant: 'success' });
          navigate(`./../${res.id}`);
        }
      });
    dispatch(loadingActions.stopLoading());
  };

  const onLineSubmit = async (values) => {
    const lineToAdd: IContractLine = {
      id: Math.random() * 900000,
      product: values.product,
      unit_price: values.unit_price.replace(',', '.'),
      initial_available_units: values.initial_available_units,
      available_units: values.initial_available_units,
      used_units: 0,
      initial_discounted_units: values.initial_discounted_units,
      available_discounted_units: values.initial_discounted_units,
      used_discounted_units: 0,
      warehouses: values.warehouses
    };

    setContractLines([...contractLines, lineToAdd]);
  };

  // const removeLine = (idx) => {
  //   setContractLines((lines) => {
  //     return lines.filter((l, i) => i !== idx);
  //   });
  // };

  // const contractLineColumns = ContractLinesColumns(
  //   translationPrefix,
  //   (line) => {
  //     const i = contractLines.findIndex((cl) => cl.id === line.id);
  //     removeLine(i);
  //   },
  //   () => null
  // );

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex justify-end">
          {initContract && <ContractInsights contract={initContract} />}
          <ButtonGroup variant="outlined" size="small">
            <Button type="submit" form="contractForm">
              {t('actions.save')}
            </Button>
          </ButtonGroup>
        </div>
      }>
      <SectionTitle>{t(`${translationPrefix}.header-section`)}</SectionTitle>
      <ContractHeaderForm
        contract={initContract}
        onSubmit={onSubmit}
        onProductsUpdate={setProducts}
        onWarehousesUpdate={setWarehouses}
      />
      <div className="py-5">
        <hr className="pb-5" />
        <SectionTitle>{t(`${translationPrefix}.line-section`)}</SectionTitle>
      </div>

      {/* <ContractLineForm */}
      {/*   onSubmit={onLineSubmit} */}
      {/*   products={products.filter( */}
      {/*     (p) => !contractLines.some((line) => +line.product['id'] === +p.id) */}
      {/*   )} */}
      {/*   warehouses={warehouses} */}
      {/* /> */}

      {/* <div className="flex flex-wrap"> */}
      {/*   <DataGridPremium */}
      {/*     autoHeight */}
      {/*     disableSelectionOnClick */}
      {/*     rows={contractLines} */}
      {/*     columns={contractLineColumns} */}
      {/*     pageSize={20} */}
      {/*   /> */}
      {/* </div> */}
      <div className="py-5 flex justify-end">
        <Button type="submit" form="contractForm" variant="contained">
          {t('actions.save')}
        </Button>
      </div>
    </TenantPage>
  );
};

export default AddContract;
