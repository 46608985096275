import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { baseCell } from '../cells/base-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import { numberFilterOperators } from '../../../../constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataGridCell from 'components/common/DataGridCell';
import { currencyCell } from '../cells/currency-cell';

export function ContractLinesColumns(handleEdit?: any, handleManualDelete?: any): GridColDef[] {
  const { t } = useTranslation();
  const translationPrefix = 'entities.movement';

  const columns: GridColumns | any[] = [
    {
      field: 'product',
      headerName: t(`global.product`),
      width: 100,
      valueGetter: (params) => params.row.product.code
    },
    {
      field: 'product_description',
      headerName: t(`global.product_description`),
      width: 250,
      valueGetter: (params) => params.row.product.description
    },
    {
      field: 'unit_price',
      headerName: t(`entities.contract_line.unit_price`),
      width: 120,
      align: 'center',
      //editable: true,
      filterOperators: numberFilterOperators,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'initial_available_units',
      headerName: t(`entities.contract_line.initial_available_units`),
      width: 120,
      align: 'center',
      //editable: true,
      renderCell: baseCell
    },
    {
      field: 'available_units',
      headerName: t(`entities.contract_line.available_units`),
      align: 'center',
      width: 120,
      renderCell: baseCell
    },
    {
      field: 'initial_discounted_units',
      headerName: t(`entities.contract_line.initial_discounted_units`),
      align: 'center',
      width: 150,
      //editable: true,
      renderCell: baseCell
    },
    {
      field: 'available_discounted_units',
      headerName: t(`entities.contract_line.available_discounted_units`),
      align: 'center',
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'warehouses',
      headerName: t(`global.warehouses`),
      width: 200,
      sortable: false,
      valueGetter: (params) => params.value && params.value.join(', '),
      renderCell: (params: GridRenderCellParams) => {
        return <DataGridCell content={params.value} />;
      }
    },
    {
      field: '',
      width: 100,
      type: 'actions',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={1}
            label={t('actions.edit')}
            icon={<EditIcon />}
            onClick={() => handleEdit(params.row)}
          />,
          <GridActionsCellItem
            key={2}
            label={t('actions.delete')}
            icon={<DeleteIcon color="error" />}
            onClick={() => handleManualDelete(params.row['id'])}
          />
        ];
      }
    }
  ];

  return columns;
}
