import { TenantPage } from 'components/common';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { equipmentInvoiceLineService } from 'services/api';
import { EquipmentInvoiceLinesColumns } from 'components/common/enhanced/common-headers/equipment-invoice-lines';

export function ExpectedEquipmentInvoices() {
  const { t } = useTranslation();
  const translationPrefix = 'pages.equipment-invoices.list';
  const datagridRefresh = useRef(null);

  const columns = EquipmentInvoiceLinesColumns(datagridRefresh);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <EnhancedDataGrid
        columns={columns}
        service={equipmentInvoiceLineService}
        getMethod={equipmentInvoiceLineService.getUnassigned}
        refresh={datagridRefresh}
      />
    </TenantPage>
  );
}

export default ExpectedEquipmentInvoices;
